<script>
    import {onDestroy, onMount} from "svelte";
    import {gsap} from "gsap";
    import ScrollTrigger from "gsap/dist/ScrollTrigger";
    import {showFullHeader} from "../stores/InformaAppStore.js";
    import decodeHtmlEntities from "../helpers/decodeHtmlEntities";

    gsap.registerPlugin(ScrollTrigger);

    export let data;
    let tl;

    let currentYear = new Date().getFullYear();

    onMount(() => {

        let menuLink = document.querySelector("a[data-section='contact-us']")

        tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#contact-us",
                start: "top 80%",
                toggleActions: "play pause resume reverse",
                onEnter: () => {
                    showFullHeader.set(true);

                    if (menuLink === null) return;
                    menuLink.classList.add('active')
                },
                onLeave: () => {

                    showFullHeader.set(false);
                    if (menuLink === null) return;
                    menuLink.classList.remove('active')
                },
                onLeaveBack: () => {
                    showFullHeader.set(false);

                    if (menuLink === null) return;
                    menuLink.classList.remove('active')
                    document.activeElement.blur()
                }
            }
        });

        tl.to('.footer__inner', {
            y: 0,
            duration: 1.5,
            ease: "power2.out"
        })
        tl.to(['.footer__content', '.footer__base-content', '.footer__image-credit'], {
            opacity: 1,
            duration: 1.5,
            ease: "power2.out",
        })

    })

    onDestroy(() => {
        if (tl !== undefined) {
            tl.scrollTrigger.kill();
        }
    })
</script>
<footer class="footer" id="contact-us">
    <div class="footer__inner">
        <div>
            <div class="footer__circle"></div>
        </div>
        <div class="footer__content-grid">
            <div class="footer__content">
                <h2 class="text-white font-aleo-regular text-30px sm:text-40px lg:text-50px">{@html decodeHtmlEntities(data.sectionHeading)}</h2>
                <p class="text-white font-aleo-light text-20px sm:text-30px lg:text-36px">{@html decodeHtmlEntities(data.leadingText)}</p>
                <div class="h-40"></div>
                <div class="footer__button-wrapper">
                    {#each data.ctaButtons as buttons }
                        <div>
                            <a href={buttons.buttonUrl} role="button" target="_blank"
                               class="secondary col-span-1">{buttons.buttonLabel}</a>
                        </div>
                    {/each}
                </div>
            </div>
        </div>

        <div class="footer__base">
            <div class="footer__image-credit">
                <p>Images on this site may be subject to copyright © iStock</p>
            </div>
            <nav class="footer__base-content">
                <span class="whitespace-nowrap">Informa PLC {currentYear}</span>
                <ul class="flex gap-16">
                    <li><a href="https://www.informa.com/privacy-policy/" target="_blank" class="hover:underline transition whitespace-nowrap">Privacy
                        Policy</a></li>
                </ul>
            </nav>
        </div>
    </div>
</footer>

<style lang="scss">
  .footer {
    overflow: hidden;
    background-color: var(--color-light-grey);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;

    scrollbar-width: none; /* Hide the scrollbar */

    &::-webkit-scrollbar {
      display: none; /* Hide the scrollbar in Webkit-based browsers */
    }

    &__inner {
      transform: translateY(100%);
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 75%;
    }

    &__circle {
      z-index: -1;
      border-radius: 1000vw 1000vw 0 0;
      width: 100vw;
      height: 50vw;
      @apply bg-sky;

      @screen sm {
        display: none;
      }

      @screen md {
        display: block;
      }
    }

    &__content-grid {
      @apply grid grid-cols-8 gap-16 flex-1 h-full bg-sky;

      @screen sm {
        @apply grid-cols-12;
        background-color: var(--color-sky);

        border-radius: 1000vw 1000vw 0 0;
        width: 100vw;
        height: 50vw;

        padding-top: 20%;

      }

      @screen md {
        background-color: transparent;
        position: absolute;
        @apply grid-cols-16;
        padding-top: 15%;
      }

      @screen lg {
        padding-top: 10%;
      }

    }

    &__content {

      @apply bg-sky text-center;
      @apply col-span-6 col-start-2;
      margin-top: -2rem;
      opacity: 0;

      @screen sm {
        @apply col-span-8 col-start-3;
        background-color: transparent;
        padding-bottom: 100px;
        margin-top: initial;
      }

      @screen md {
        @apply col-span-8 col-start-5;
        padding-bottom: 0;
      }
    }

    &__button-wrapper {
      @apply grid grid-cols-1 gap-16;

      @screen sm {
        @apply px-64;
      }

      a {
        white-space: nowrap;
      }
    }

    &__base {
      @apply grid grid-cols-8 bg-sky py-40;

      @screen sm {
        @apply grid-cols-12;
      }

      @screen md {
        background-color: transparent;
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }

    &__base-content {
      opacity: 0;
      border-top: 2px solid var(--color-indigo);

      @apply flex justify-between py-16 text-12px md:text-16px flex-col items-center gap-8;
      @apply col-span-6 col-start-2;
      @screen sm {
        @apply col-span-10 col-start-2 flex-row;
      }
    }

    &__image-credit {
      opacity: 0;
      display: flex;
      justify-content: start;
      margin-bottom: 1rem;
      @apply text-12px col-span-6 col-start-2;

      @screen sm {
        @apply col-span-10 col-start-2;
      }
    }
  }

</style>